<template>
  <div class="w-full p-4 flex flex-col">
    <section class="flex flex-col w-full mt-4 mb-8">
      <h2 class="text-3xl font-bold mb-4">
        {{ translatedValue("learningCard.top.title") }}
      </h2>
      <div class="flex flex-col">
        <div v-if="units.length === 0" class="mt-4">
          <p v-if="!loading">
            {{ translatedValue("learningCard.top.noUnitMessage") }}
          </p>
          <p v-else>{{ translatedValue("learningCard.top.fetching") }}...</p>
        </div>
        <div v-else>
          <div
            v-for="(unit, i) in units"
            :key="i"
            class="px-3 py-2 flex flex-col bg-white rounded-md mb-4 cursor-pointer"
            @click="openDisplayModal(unit.ref.id)"
          >
            {{ unit.data.name }}
          </div>
        </div>
      </div>
    </section>
  </div>
  <MSReflectionDisplayModal
    v-if="isDisplayModalOpen"
    :student="student"
    :unit="unit"
    can-edit
    @close="closeDisplayModal"
    @aiReflectionUpdate="handleAiReflectionUpdate"
  />
</template>

<script lang="ts">
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import MSReflectionDisplayModal from "@/components/MSReflectionDisplayModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { awaitStudentLoaded } from "@/store/index";
import { Student } from "@/entities/student";
import { Unit, unitCollectionKey, convertToUnit } from "@/entities/reflection";

@Options({
  components: {
    MSInfoItem,
    MSReflectionDisplayModal
  }
})
export default class Todos extends Vue {
  student: Student | null = null;
  unit: Unit | null = null;
  units: Unit[] = [];
  loading = false;
  isDisplayModalOpen = false;

  get translatedValue() {
    return store.state.translatedValue;
  }

  openDisplayModal(unitId: string) {
    const targetUnit = this.units.find(_ => _.ref.id === unitId);
    if (!targetUnit) {
      // 通常ありえない
      alert("指定の単元が見つかりません。");
      return;
    }
    this.unit = { ...targetUnit };
    this.isDisplayModalOpen = true;
  }

  closeDisplayModal() {
    this.isDisplayModalOpen = false;
    this.unit = null;
  }

  handleAiReflectionUpdate(result: { unitId: string; content: string }) {
    if (!this.unit) return;
    if (!result.unitId) return;
    if (this.unit.data.uid !== result.unitId) return; //普通あり得ない
    const { unitId, content } = result;
    this.unit.data.aiReflection = content;
    this.units = this.units.map(u =>
      u.data.uid === unitId ? ({ ...this.unit } as Unit) : ({ ...u } as Unit)
    );
  }

  async created() {
    this.loading = true;
    this.student = (await awaitStudentLoaded(store)) as Student;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.loading = false;
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "情報取得中...");

      const snapshot = await this.student.ref
        .collection(unitCollectionKey)
        .get();

      this.units = !snapshot.empty
        ? snapshot.docs
            .filter(doc => doc.exists && doc.data())
            .map(doc => convertToUnit(doc.data(), doc.ref))
        : [];
    } catch (e) {
      alert("情報の取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.loading = false;
    }
  }
}
</script>
